import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Markdown from 'components/common/markdown/Markdown';
import ProductRichSnippet from 'components/common/rich-snippets/product/Product';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import Ads from 'components/directus/ads/Ads';
import BankAdsGrid from 'components/directus/bank-ads-grid/BankAdsGrid';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import FaqInfoPanel from 'components/directus/faq-info-panel/FaqInfoPanel';
import CustomerTestimonials from 'components/directus/case-study-testimonial/CaseStudyTestimonial';
import PackageInclusions from 'components/directus/package-inclusions/PackageInclusions';
import PageTitle from 'components/directus/page-title/PageTitle';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import packagePageHook from 'hooks/package-page/package-page.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

import { privacyPackage__content, privacyPackage__footnote } from 'pages-styles/package/privacy-package.scss';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'privacy-package';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);
  const { privacyPackage } = packagePageHook();

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
      </Helmet>
      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} />
        </Section>
        <Section bottom={25}>
          <Markdown source={privacyPackage.content} container className={privacyPackage__content} />
        </Section>
        <Section bottom={75}>
          <PackageInclusions slug={slug} />
        </Section>
        <Section>
          <CustomerTestimonials slug="william-and-watson" />
        </Section>
        <Section bottom={50}>
          <Ads slug="telephone-order" />
        </Section>
        <Section bottom={30}>
          <FaqInfoPanel slug={slug} />
        </Section>
        <Section bottom={50}>
          <Markdown source={privacyPackage.footnote} container className={privacyPackage__footnote} />
        </Section>
        <Section>
          <BankAdsGrid />
        </Section>
        <Section>
          <CustomerReviews />
        </Section>
      </Wrapper>
      <ProductRichSnippet slug={slug} />
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "privacy-package" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
